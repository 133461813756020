import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../assets/blackout_logo.png';
import Discord from '../assets/discord.png';

import Dropdown from './Dropdown';
import HamburgerDropdown from './HamburgerDropdown';

import '../styles/Navbar.css';

function Navbar() {

    const [openMenu, setMenuState] = useState(false);

    const toggleNavbar = ()=>{
        setMenuState(!openMenu);
    }

    return (
        <div className="navbar">
            <div className='logo'>
                <Link to='/'>
                    <img src={Logo}/>
                </Link>
            </div>

            <div className='menu'>                                 
                <Dropdown title='Unity Assets' options={[
                    { label: 'Runtime Curve Editor', link: '/curve-editor' },
                    // { label: 'Build System', link: '/build-system' }
                ]}/>
                            
                <Link to='/contact'>Contact</Link>
                <Link to="https://discord.gg/PrbTbKg5K3">
                    <img src={Discord} width={32}/>
                </Link> 

            </div>

            <div className='navbutton'>
                <HamburgerDropdown options={[
                    { label: 'Runtime Curve Editor', link: '/curve-editor' },
                    // { label: 'Build System', link: '/build-system' },
                    { label: 'Contact', link: '/contact'},
                    { label: 'Discord', link: "https://discord.gg/PrbTbKg5K3"}
                ]}/>
            </div> 

        </div>
    )
}

export default Navbar
