import React from 'react'
import "../styles/Bubbles.scss";

function Home() {   
  return (    
    <div className='gradient-bg'>
      <svg xmlns='http://www.w3.org/2000/svg'>
        <defs>
          <feGaussianBlur in='SourceGraphic' stdDeviation='10' result='blur' />
          <feColorMatrix in='blur' mode='matrix' values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8' result='goo' />
          <feBlend in='SourceGraphic' in2='goo' />
        </defs>
      </svg>
      <div className='gradients-container'>
        <div className='g1'></div>
        <div className='g2'></div>
        <div className='g3'></div>
        <div className='g4'></div>
        <div className='g5'></div>        
      </div>
      <div className='text'>
          A small indie studio building video games and unique assets for game developers
      </div>
    </div>    
  )
}

export default Home