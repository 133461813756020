import React from "react";
import MultiplePizzas from "../assets/multiplePizzas.jpeg";
import "../styles/About.css";

function About() {
  return (
    <div className="about">
      <div className="top"
        style={{ backgroundImage: `url(${MultiplePizzas})` }}
      ></div>
      <div className="bottom">
        <h1> ABOUT US</h1>
        <p>
          We make 
        </p>
      </div>
    </div>
  );
}

export default About;