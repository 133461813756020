import { React, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import '../styles/Dropdown.css';

const Dropdown = ({ title, options }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const handleCloseOnResize = () => {
        setIsOpen(false); // Close the dropdown when the window is resized
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        window.addEventListener('resize', handleCloseOnResize); // Add event listener for window resize
        return () => {
            document.removeEventListener('click', handleClickOutside);
            window.removeEventListener('resize', handleCloseOnResize); // Remove event listener on component unmount
        };
    }, []);

    return (
        <div className="dropdown" ref={dropdownRef}>
            <Link onClick={toggleDropdown}>{title}</Link>    
            {isOpen && (
                 <div className="dropdown-content">                
                    {options.map((option, index) => (
                        <li key={index}>
                            <Link to={option.link}>{option.label}</Link>
                        </li>
                    ))}                    
                 </div>
            )}
        </div>
    );
};

export default Dropdown;