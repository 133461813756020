import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Flip, ToastContainer, toast } from 'react-toastify'; 
import Image from "../assets/pizzaLeft.jpg";
import "../styles/Contact.css";
import 'react-toastify/dist/ReactToastify.css';

function Contact() {  
  const form = useRef();
  const [disable, setDisable] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    const serviceId = "service_an70uma";
    const templateId = "template_heoakeu";
    const userId = "H7XVaeTV-8BQt3348";
    
    setDisable(true);

    emailjs
      .sendForm(
        serviceId, templateId, form.current, userId
      ).then(
        () => {
          console.log('Email has been sent!');          
          toast.success("Email sent, we will get back to you shortly!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Flip,
          });   
          setDisable(false);  
          form.current.reset();  
        },
        (error) => {
          console.log('Email failed to send...', error.text);
          toast.error("Email failed to send: ".concat(error.text), {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Flip,
            });
            setDisable(false);
        },
      );
  };

  return (
    <div className="contact">
      <div
        className="leftSide"
        style={{ backgroundImage: `url(${Image})` }}
      ></div>
      <div className="rightSide">
        <h1> Contact Us</h1>
        <ToastContainer position='bottom-right' autoClose='5000' theme='dark'/>
        <form ref={form} id="contact-form" onSubmit={sendEmail}>                     
          <label htmlFor="name"><b>Name</b></label>
          <input name="name" placeholder="Enter name..." type="text"/>
          <label htmlFor="email"><b>Email</b></label>
          <input name="email" placeholder="Enter email..." type="email"/>
          <label htmlFor="message"><b>Message</b></label>
          <textarea
            rows="10"
            placeholder="Enter message..."
            name="message"
            required 
          ></textarea>
          <button type="submit" value="Send" disabled={disable}><b>Send Message</b></button>          
        </form>
      </div>
    </div>
  )
}

export default Contact
