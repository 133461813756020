import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";


import "../styles/Footer.css";

function Footer() {
  return (
    <div className="footer">      
      <p> &copy; 2024 blackoutstudios.co</p>
    </div>
  );
}

export default Footer;