import React from 'react'
import ImageGallery from "react-image-gallery";
import { Link } from 'react-router-dom';

import Store1 from '../assets/curve-editor/store-1.png';
import Store2 from '../assets/curve-editor/store-2.png';
import Store3 from '../assets/curve-editor/store-3.png';
import Store4 from '../assets/curve-editor/store-4.png';
import Store5 from '../assets/curve-editor/store-5.png';

import "react-image-gallery/styles/css/image-gallery.css";
import '../styles/Product.css';

const images = [
  {
    original: Store1,
    thumbnail: Store1,
    originalHeight: 300,
  },
  {
    original: Store2,
    thumbnail: Store2,
    originalHeight: 300,
  },
  {
    original: Store3,
    thumbnail: Store3,
    originalHeight: 300,
  },
  {
    original: Store4,
    thumbnail: Store4,
    originalHeight: 300,
  },
  {
    original: Store5,
    thumbnail: Store5,
    originalHeight: 300,
  },
];


function CurveEditor() { 
  return (
    <div className='product'>
      <div className='content'>
        <h1>Runtime Curve Editor</h1>
        <p/>
        <ImageGallery items={images} showPlayButton={false} />

        <div className='tab-group' >
          <div className='tab'><Link to='https://github.com/blackout-studios/curve-editor-docs' className='tab'>Documentation</Link></div> 
          <div className='tab'><Link to="https://www.blackoutstudios.co/demos/Runtime Animation Curve Editor.zip" className='tab'>Demo</Link></div> 
          <div className='tab'><Link to="https://u3d.as/3dcS" className='tab'>Store</Link></div>  
        </div>   

        <p>A runtime AnimationCurve editor made using Unity UI</p>   
        
        <p><b>Features</b></p>
        <li>Tested on all render pipelines</li>
        <li>Tested in all versions of Unity from 2019.1 up to 2022.3 (with .NET 4x | NET standard 2.1 backend)</li>
        <li>Contains all the functionality of Unity's in-built curve editor</li>
        <li>Has ability to flip and normalize curves</li>
        <li>The editor window can be dragged around and resized</li>
        <li>Undo/redo support</li>
        <li>Supports pre-made animation curve presets</li>
        <li>Support for using either UnityEngine.UI or TextMeshPro for text/input components</li>
        <li>Fully documented source code</li>
        <li>Curves are drawn with UI Graphic components</li>
        <li>Grid is drawn with a UI shader</li>
      </div>
      </div>
  )
}

export default CurveEditor
